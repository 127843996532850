var Barba = require('barba.js');

var HideShowTransition = Barba.BaseTransition.extend({
    start: function() {
        var that = this;

        var oldAside = that.oldContainer.querySelector('.main-aside');
        oldAside.style.height = oldAside.offsetHeight;

        new Promise(function(res) {
            var element = document.body;
            if (element.scrollTop === 0) {
                element = document.querySelector('.main-container');
            }

            function scrollTop() {
                var difference = element.scrollTop;
                var perTick = 10;

                setTimeout(function() {
                    element.scrollTop = element.scrollTop - perTick;
                    if (element.scrollTop === 0) {
                        res();
                        return
                    }
                    scrollTop();
                }, 1);
            }

            scrollTop();
        })
        .then(function() {
            return that.newContainerLoading;
        })
        .then(function() {
            var sidebarHeight = that.newContainer.querySelector('.main-aside').offsetHeight;
            that.newContainer.style.display = 'none';
            return Promise.all([that.handleContent(), that.handleSidebar(sidebarHeight)]);
        })
        .then(function() {
            that.newContainer.style.display = '';
            that.done();
        });
    },
    handleContent: function() {
        var that = this;

        var contentContainer = that.oldContainer.querySelector('.main-content');
        var oldContent = contentContainer.innerHTML;

        contentContainer.innerHTML = '<div class="pagetransition-container">' + oldContent + '</div>';

        return this.newContainerLoading.then(function() {
            var newContent = that.newContainer.querySelector('.main-content').innerHTML;
            contentContainer.innerHTML += '<div class="pagetransition-container">' + newContent + '</div>';
        })
        .then(function() {
            that.oldContentEl = contentContainer.querySelectorAll('.pagetransition-container')[0];
            that.newContentEl = contentContainer.querySelectorAll('.pagetransition-container')[1];

            return new Promise(function(res) {
                that.newContentEl.style.left = that.direction == 'right' ? '-110%' : '110%';
                that.oldContentEl.style.left = '0%';
                that.newContentEl.style.transition = 'left 300ms';
                that.oldContentEl.style.transition = 'left 300ms';

                setTimeout(res, 100);
            });
        })
        .then(function() {
            that.newContentEl.style.left = '0%';
            that.oldContentEl.style.left = that.direction == 'right' ? '110%' : '-110%';

            return new Promise(function(res) { setTimeout(res, 300); });
        });
    },

    handleSidebar: function(sidebarHeight) {
        var that = this;
        var newAside;

        return this.newContainerLoading.then(function() {
            newAside = that.newContainer.querySelector('.main-aside').cloneNode(true);
            newAside.style.position = 'absolute';
            newAside.style.opacity = '0';
            newAside.style.transition = 'opacity 300ms';
            newAside.style.height = sidebarHeight;

            that.oldContainer.appendChild(newAside);
            return new Promise(function(res) { setTimeout(res, 100); });
        })
        .then(function() {
            newAside.style.opacity = '1';
            return new Promise(function(res) { setTimeout(res, 300); });
        });
    }
});

Barba.Pjax.getTransition = function() {
    return HideShowTransition;
};

Barba.Dispatcher.on('linkClicked', function(el) {
    var nav = document.querySelector('.main-header > nav.navigation');

    var currentIndex;
    var clickedIndex = undefined;

    nav.querySelectorAll('li').forEach(function(e, i) {
        if (e.classList.contains('highlighted')) {
            currentIndex = i;
        }

        if (e == el.parentElement) {
            clickedIndex = i;
        }
    });

    if (clickedIndex == undefined) {
        Barba.Pjax.getTransition().direction = 'left';
    } else if (clickedIndex > currentIndex) {
        Barba.Pjax.getTransition().direction = 'left';
    } else {
        Barba.Pjax.getTransition().direction = 'right';
    }
});

Barba.Pjax.start();
