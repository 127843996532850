var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

global.onYouTubeIframeAPIReady = function() {
    preparePlayer();
}

var Barba = require('barba.js');
Barba.Dispatcher.on('transitionCompleted', function() {
    preparePlayer();
});


function preparePlayer() {
    var players = [];

    document.querySelectorAll('.album-container').forEach(function(albumEl) {
        var el = albumEl.querySelector('[data-youtube-id]');
        var playlistId = el.attributes['data-youtube-id'].value;
        var player = new YT.Player(el, {
            height: '360',
            width: '640',
            playerVars: {
                listType: 'playlist',
                list: playlistId
            },
            events: {
                'onReady': function() {
                },
                'onStateChange': function(ev) {
                    player.getIframe().style.opacity = 1;
                    if (ev.data == YT.PlayerState.PLAYING) {
                        var index = player.getPlaylistIndex();
                        albumEl.querySelectorAll('.tracklist li')[index].classList.add('playing');
                    } else {
                        albumEl.querySelectorAll('.tracklist li').forEach(function(x) {
                            x.classList.remove('playing');
                        });
                    }
                }
            }
        });

        albumEl.querySelectorAll('.tracklist li').forEach(function(trackEl, trackIndex) {
            trackEl.addEventListener('click', function() {
                if( window.innerWidth <= 700 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    // nicht abspielen
                    window.open(trackEl.attributes['data-href'].value, '_blank');
                    return;
                }
                var playerIndex = player.getPlaylistIndex();
                if (playerIndex == trackIndex && player.getPlayerState() == YT.PlayerState.PLAYING) {
                    player.pauseVideo();
                } else if (playerIndex == trackIndex && player.getPlayerState() == YT.PlayerState.PAUSED) {
                    player.playVideo();
                } else {
                    players.forEach(function(p) {
                        p.pauseVideo();
                    });
                    player.getIframe().style.opacity = 1;
                    player.playVideoAt(trackIndex);
                }
            });
        });

        players.push(player);
    });

}
